import { useCartStore } from "~/pinia/cart"
import { useNotificationStore } from "~/pinia/notifications"

export default function useLogout() {
  const { api } = useApi()
  const { $store, $router, $csAuth } = useNuxtApp()
  const { handleError } = useErrorHandling()
  const notificationStore = useNotificationStore()

  const logOutMessage = ref("Successfully logged out")

  const logout = async () => {
    await $csAuth
      .logout()
      .then(async () => {
        await api("/cs/api/csrf_token")
        await $store.commit("nav/setNavigationState", false)
        useCartStore().resetCart()
        $router.push("/")

        notificationStore.add({
          message: logOutMessage.value,
          type: "success",
        })
      })
      .catch(handleError)
  }

  return {
    logout,
    logOutMessage,
  }
}
